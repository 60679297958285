.ContentDoubleCardWithImage {
    @mixin wrapper;
    @mixin blockVerticalPadding 32px, 64px;
}

.Items {
    @mixin clearList;

    @media (--tablet) {
        display: flex;
    }

    & li {
        margin-bottom: 32px;

        @media (--tablet) {
            lost-column: 1/2;
            margin-bottom: 0;
        }

        &:last-child {
            @media (--mobileOnly) {
                margin-bottom: 0;
            }
        }
    }
}
